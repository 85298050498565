import React, { FunctionComponent, useEffect, useState } from "react";
import { ArrowRight } from "web-common/src/images/icons/arrow-right-two";
import Container from "react-bootstrap/Container";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { ImageInterface, PortableText, MultiLineHeadline, SanityColorList } from "web-common/src/types/SanityTypes";
import { ColorVariations } from "web-common/src/types/ComponentTypes";
import "./styles.scss";

export interface IframePromotionInterface {
  _rawImage: ImageInterface;
  headline?: MultiLineHeadline;
  bgColor: SanityColorList;
  _rawIntroduction?: PortableText;
  ctaLabel: string;
  iFrameUrl: string;
  iFrameScript: string;
  iFrameId: string;
  // For Preview
  image?: ImageInterface;
  introduction?: PortableText;
}

const IframePromotion: FunctionComponent<IframePromotionInterface> = ({
  _rawImage,
  image,
  headline,
  bgColor,
  _rawIntroduction,
  introduction,
  ctaLabel,
  iFrameUrl,
  iFrameScript,
  iFrameId
}) => {
  const iframeImage = _rawImage || image;
  const iframeIntro = _rawIntroduction || introduction;
  const showHeader = iframeImage && headline ? true : false;

  const bigUrl = iframeImage?.asset?.url + "?fm=webp";

  useEffect(() => {
    const scriptId = `iframe-script-${iFrameId}`;
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = iFrameScript;
      document.body.appendChild(script);
    }
  }, [iFrameId, iFrameScript]);

  const colorVariations: ColorVariations = {
    Blue: {
      primaryGradientColor: "var(--blue-dark-color)",
      secondaryGradientColor: "var(--blue-bright-color)",
      gradientDegrees: "90"
    },
    Pink: {
      primaryGradientColor: "var(--pink-color)",
      secondaryGradientColor: "var(--pink-color)",
      gradientDegrees: "90"
    }
  };
  const renderGradient = `linear-gradient(${colorVariations[bgColor.title].gradientDegrees}deg, ${colorVariations[bgColor.title].primaryGradientColor
    } 0%, ${colorVariations[bgColor.title].secondaryGradientColor} 94%)`;

  const linkColor = bgColor.title === "Blue" ? "promo-block-link-pink" : "promo-block-link-blue";

  const iframeTitle = headline?.primaryText + " " + (headline?.secondaryText || "") + " " + (headline?.tertiaryText || "");

  const renderAnchorId = iframeTitle.replace(/ /g, "-");

  const renderContent = (sectionClasses: string, textClasses: string, linkClasses: string) => {
    sectionClasses += ` ${iFrameId}`

    return (
      <>
      {showHeader && (
        <section className={sectionClasses} style={{ background: renderGradient, color: "white" }}>
          <Container fluid>
            <div className="row no-gutters">
              <div className="col-12">
                <h2 id="header1">
                  {headline?.primaryText}
                  {headline?.secondaryText ? <span id="header2">{headline?.secondaryText}</span> : null}
                  {headline?.tertiaryText ? <span id="header3">{headline?.tertiaryText}</span> : null}
                </h2>
              </div>
              {iframeIntro ? (
                <div className={textClasses}>
                  <div className="body-copy" id="promoBlockCopy">
                    <BlockContent blocks={iframeIntro} />
                  </div>
                </div>
              ) : null}
            </div>
          </Container>
        </section>
      )}
        <section>
          <Container className={"iframe-visible"} id={renderAnchorId} fluid style={{ height: "800px" }}>
            <iframe
              src={iFrameUrl}
              title={iframeTitle}
              frameBorder="0"
              scrolling="yes"
              width="100%"
              height="100%"
              id={iFrameId}
            />
          </Container>
        </section>
      </>
    );
  };

  return (
    <>
      <style>
        {`
          .content-background {
            background: linear-gradient(90deg, #221b94 0%, #4238fa 94%);
          }
          @media (min-width: 768px) {
            .${iFrameId} .container-fluid {
              background: url(${bigUrl}) no-repeat;
              background-size: contain;
              background-position: 300px;
            }
          }
          @media (min-width: 992px) {
            .content-background .container-fluid {
              background-position: 98%;
            }
          }
        `}
      </style>
      {renderContent(
        "iframe-promotion-block content-background",
        "col-12 col-md-7 offset-lg-1",
        "col-12 col-md-5 col-lg-4 ball"
      )}
    </>
  );
};

export default IframePromotion;
